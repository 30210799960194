html,body{
    overflow-x: hidden;
}
.App{
    margin-top: inherit;
}
.MainHeader{
    position: inherit;
}
.BannerWithText{
    padding: 10vh 0 !important;
    
    .slider-video{
        transform: scale(3.4);
    }

    &.white{
        &.world{
            .BannerWithText_heading{
                img{
                    display: none;
                }
            }
        }
    }
    .BannerWithText_heading{
        font-size: 2.6rem !important;
    }
    .BannerWithText_paragraph{
        font-size: 1rem !important;
    }
}

.HomeComponent{
    .engineContainer{
        padding-top: 8vh;
        padding-bottom: 0;
        .col-md-4{
            padding-top: 0 !important;
        }
        .col-md-8{
            text-align: center !important;

            img{
                display: inline-block;
                margin: 10px;
            }
        }
    }

    .titleContainer{
        &.override{
            background: #f4d506;
            top: 0;
            margin: 0;
            margin-bottom: -20px;
            padding-top: 20px;

            h2{
                font-size: 20px;
            }
        }

        &.ambisyon2040 {
            img {
                width: 150px;
            }
            h3{
                font-size: 18px !important;
                text-align: center;
            }
            .col-md-5 {
                padding: 15px 0 !important;
            }
        }
    }
}

.BannerWithSlider{
    .slideContainer{
        max-height: 290px;
        padding: 8vh 0 !important;
        background-size: cover !important;
        min-height: 200px !important;
    
        h1{
            font-size: 2rem;
            // padding-left: 50px;
            // padding-right: 50px; 
        }
    }
}

.SkillContainer{
    .row{
        background-attachment: inherit !important;
        background-size: contain !important;
        background-repeat: no-repeat;
        background-position: top center !important;

        .box{
            padding: 0 !important;
            background: none !important;
            min-height: inherit !important;

            .col-md-5{
                background: transparent !important;
                padding: 33vh 20px 5vh 20px !important;
                color: #000;

                h3{
                    // display: none;
                    margin-top: -20vh;
                    text-align: center;
                    padding-bottom: 16vh;
                    color: #ffffff;
                    text-shadow: 2px 2px 1px #000;
                    font-size: 1.5rem;
                }
                
                a{
                    color: #000 !important;
                    width: 100% !important;
                    display: inline-block;
                    text-align: center;
                    background: #eaeaea;
                }
            }
        }
    }
}

.FaqComponent{

    .container {
        &.contentBox{
            button{
                font-size: 18px;
                padding-right: 60px;
                position: relative;
        
                .expandIcon {
                    position: absolute;
                    right: 15px;
                    top: calc(50% - 15px);
                }
            }
        
            // img{
            //     width: calc(100% + 40px);
            //     margin-left: -20px;
            // }
            // .imageWrap {
                
            //     width: calc(100% + 40px);
            //     margin-left: -20px;
        
            //     img{
            //         width: 100%;
            //         margin-left: 0;
            //     }
        
            //     p{
            //         font-size: 12px !important;
            //     }
            // }
        }
    }

    .conferencesList{
        .listHeading{
            display: none;
        }
        .row{
            border-top: inherit;
            padding: 15px 0;
            // border-bottom: 1px #eaeaea solid;

            .col-md-2,
            .col-md-5 {
                // padding: 0;

                &:first-child{
                    // font-style: italic;
                    color: #666;
                    font-size: 12px;
                    font-weight: bold;
                }
                &:nth-child(2){
                    font-style: italic;
                    font-weight: bold;
                }

            }
        }
    }

    .galleryBox{
        img{
            max-height: inherit;
            max-width: 100%;
        }
    }

    .ourClients{
        .clientBox{
            text-align: center;
            margin-bottom: 30px;
            .col-xs-6{
                width: 100%;
                
                img{
                    margin-bottom: 10px;
                }
            }
        }
    }
    
}

.TalentMapStatsComponent{
    margin-top: 0 !important;
}

.AdoptACityComponent {
    .firstContainer,
    .lastContainer {
        min-height: 70vh !important;
        h1, p {
            font-size: 2rem !important;
        }
    }
    .btn-warning{
        &.yellow {
            margin-bottom: 20px !important;
        }
    }

    &.AdoptACityUSAComponent {
        .titleContainer {
            h3 {
                font-size: 1.5rem !important;
            }
        }
        
        .firstContainer {
            h1 {
                font-size: 1.5rem !important;
                padding-left: 20px;
                padding-right: 20px;
            }

            &.one {
                background-size: 130%;
                background-position: bottom;
                background-color: #ffb413;
                min-height: 50vh !important;
            }

            &.two {
                background-size: 110%;
                background-position: bottom;
                background-color: #f6f6f8;
                min-height: 40vh !important;
            }

            &.three {
                background-size: cover;
                background-position: left -200px top;
                background-color: #f6f6f8;
                min-height: 40vh !important;
                .col-md-6 {
                    padding: 8vh 0px !important;
                }
                h1 {
                    font-size: 1.2rem !important;
                }
                img{
                    width: 200px !important;
                }
            }

            &.four {
                background-size: cover;
                background-position: right -80px top;
                background-color: #f6f6f8;
                min-height: 40vh !important;
                .col-md-6 {
                    padding: 13vh 0px 0 0 !important;
                }
                h1 {
                    font-size: 1.2rem !important;
                }
            }

            &.five {
                background-size: cover;
                background-position: top;
                background-color: #f6f6f8;
                min-height: 40vh !important;

                .col-md-6 {
                    padding: 10vh 0px 0 0 !important;
                }

                h1 {
                    text-shadow: 0px 0px 5px #fff;
                }
            }

            &.six {
                background-size: cover;
                background-position: top;
                background-color: #f6f6f8;
                min-height: 40vh !important;

                .col-md-6 {
                    padding: 10vh 0px 0 0 !important;
                }

                h1 {
                    text-shadow: 0px 0px 5px #fff;
                }
            }

            &.seven {
                background-size: cover;
                background-position: right -150px top;
                background-color: #f6f6f8;
                min-height: 60vh !important;

                .col-md-5 {
                    padding: 0vh 0px 0 0 !important;
                }
                h1 {
                    text-shadow: 0px 0px 5px #000;
                }

            }

        }
    }

}
.AdoptCitySpecific {
    min-height: 75vh !important;
    h1, p {
        font-size: 2rem !important;
    }
    .btn-warning{
        &.yellow {
            margin-bottom: inherit !important;
        }
    }


}

.bottomButtons {
    button {
        
        &:nth-child(1) {
            margin-right: 0px !important;
            margin-bottom: 30px;
        }
    }
}

.playPressBtn{
    p {
        padding-top: 40px;
        font-size: 1.1rem;
        line-height: 1.5rem;
    }
}
