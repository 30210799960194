.TextWithImage{
    padding: 10vh 0;
    position: relative;
    overflow: hidden;

    .BannerWithText_heading{
        color: #000;
        font-size: 1.8rem;
    }
    .BannerWithText_paragraph{
        margin-top: 20px;
        color: #000;
        font-size: 1rem;
    }

    .BannerWithText_btn{
        background-color: #f4d506;
        border-radius: 0;
        border: none;
        color: #000;
        margin-top: 25px;
        padding: 15px 30px;
        outline: none;

        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active{
            background-color: #000;
            border: none;
            outline: none;
        }

    }

    &.gray{
        background: #eaeaea;
    }
}