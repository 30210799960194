.FourBox{
    padding: 12vh 0;

    .labelImage{
        position: absolute;
        z-index: 99;
        width: 210px;
        left: calc(50% - 105px);
        top: calc(50% - 113px);
    }

    > .container{
         > .row {
            position: relative;

            .box{
                padding: 14vh 0;
                color: #fff;

                h2{
                    font-weight: bold;
                    font-size: 2.3rem;
                }
            }
         }
    }
}