.AdoptACityComponent {
    .firstContainer {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        color: #fff;

        h1{
            text-transform: uppercase;
            font-size: 3.5rem;
            font-weight: bold;
            margin: 10vh 0;
            text-shadow: 5px 8px 20px #000;

            .yellow{
                color: #ffc000;
            }
        }
    }

    .lastContainer {
        min-height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        color: #fff;
        text-align: left;

        h1{
            font-size: 3.5rem;
            margin: 10vh 0 0 0;
            text-shadow: 5px 8px 20px #000;

            .yellow{
                color: #ffc000;
            }
        }
        p {
            font-size: 3.5rem;
            text-shadow: 5px 8px 20px #ccc;
        }
    }

    .btn-primary {
        &.blue{
            background-color: #0e95e4;
            &:hover {
                background-color: #066ba7;
            }
            border: 3px #fff solid;
            border-radius: 0;
            min-width: 240px;
            padding: 10px 20px;
        }
    }
    .btn-warning {
        &.yellow {
            border: 3px #fff solid;
            border-radius: 0;
            color: #003c5f;
            padding: 10px 20px;
            min-width: 240px;
        }
    }

    .bottomButtons {
        button {
            
            &:nth-child(1) {
                margin-right: 50px;
            }
        }
    }


    &.AdoptACityUSAComponent {

        .titleContainer{
            background-color: rgb(0, 12, 29);
            padding: 8vh 0;
            // border-top: 1px #333 solid;
            // border-bottom: 1px #333 solid;
    
            h3{
                color: #ecaa1e;
                font-weight: bold;
            }
        }

        .firstContainer {
            min-height: 85vh;
            color: #000;

            h1{
                text-transform: none;
                font-size: 2.2rem;
                font-weight: normal;
                margin: 0;
                text-shadow: none;

                .bold{
                    font-weight: bold;
                }
            }

            

        }

    }

}