.samProfileBox {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4vh 0 7vh 0;
    z-index: 0;

    &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background: rgba(11, 37, 72, 0.9);
        background: #000000d9;
        z-index: -1;
    }

    .col {
        border-right: 1px #fff solid;
        padding-top: 0vh;
        padding-bottom: 0vh;
        position: relative;
        
        &:last-child {
            border-right: inherit;
        }

        h3 {
            font-size: 2rem;
            color: #fff;

            span {
                font-size: 4rem;
                text-decoration: underline;
            }
        }
        p {
            padding: 0 6vw;
            margin: 0;
            font-size: 1rem;
            color: #fff;
        }


    }

}

.samProfileSection {
    background: #05162d;

    .col {
        &.mainBox {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            padding: 35px;

            .sampleTag{
                position: absolute;
                z-index: 999;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                right: 40px;
                top: -30px;
                width: 95px;
                height: 83px;
                text-align: center;
                padding: 10px 0;
                font-size: 12px;
                font-weight: bold;
                color: #d63333;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                
                .first {
                    display: none;
                }

                &:hover{
                    background-image: none !important;
                    background-color: #d63333;
                    border: 2px #fff solid;
                    border-radius: 3px;
                    padding: 5px 0;
                    color: #fff;

                    .second{
                        display: none;
                    }
                    .first {
                        display: inline-block;
                        text-decoration: underline;
                    }
                }

            }
        }
    }

    .topRow,
    .bottomRow{
        padding: 10px 0px 60px 0;
        > .col {
            color: #fff;

            P {
                margin: 0;

                &:last-child {
                    color: #999;
                    font-style: italic;
                    font-size: 0.8rem;
                }
            }

            .bubbleBox {
                width: 120px;
                height: 120px;
                margin: auto;
                text-align: center;
                position: relative;

                .bubble {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    margin: auto;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.2s ease-in-out;

                    &:not(:hover){
                        box-shadow: none !important;
                    }
                }
            }
        }

    }
}

.tooltip-inner {
    &.customTooltip {
        padding: 0;
        max-width: 250px !important;
        text-align: left;
        background: transparent;

        p {
            border-radius: 5px;
        }
    }
}