.BannerWithText{
    padding: 32vh 0;
    position: relative;
    overflow: hidden;

    .BannerWithText_heading{
        color: #f4d506;
        font-size: 3.2rem;
        text-shadow: 0px 0px 30px #333;
    }
    .BannerWithText_subheading{
        color: #f4d506;
        font-size: 1.8rem;
        text-shadow: 0px 0px 30px #333;
    }
    .BannerWithText_paragraph{
        margin-top: 20px;
        color: #f4d506;
        font-size: 1.3rem;
        text-shadow: 0px 0px 30px #333;
    }

    .BannerWithText_btn{
        background-color: #f4d506;
        border-radius: 0;
        border: none;
        color: #000;
        margin-top: 25px;
        padding: 15px 30px;
        outline: none;

        &:hover,
        &:active,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active{
            background-color: #000;
            border: none;
            outline: none;
            color: #fff !important;
        }

    }

    .slider-video{
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: auto;
        z-index: -1;
    }

    &.red{
        padding: 20vh 0;
        
        .BannerWithText_heading{
            color: #cd1444;
            font-size: 2.2rem;
            font-weight: bold;
        }
    
        .BannerWithText_btn{
            background: transparent;
            border: 1px #cd1444 solid;
            color: #cd1444;
            
            &:hover,
            &:active,
            &:focus,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active{
                background: #cd1444;
                border: 1px #cd1444 solid;
                color: #000;
            }

        }
    }

    &.white{

        .BannerWithText_heading{
            color: #fff;
        }

        &.world{
            text-align: left;
            padding: 25vh 0;

            .BannerWithText_heading{
                position: relative;
                font-size: 3.4rem;
                font-weight: bold;
                // max-width: 70%;
                margin: auto;

                img{
                    position: absolute;
                    left: -40px;
                    top: -28px;
                }
            }
            .BannerWithText_paragraph{
                color: #fff;
                text-shadow: none;
                text-align: left;
            }
        }

        &.header{
            padding: 25vh 0;

            .BannerWithText_heading{
                position: relative;
                font-size: 4rem;
                font-weight: bold;
                max-width: 70%;
                margin: auto;

                img{
                    position: absolute;
                    left: 10px;
                    top: -28px;
                }
            }
            .BannerWithText_paragraph{
                color: #fff;
                text-shadow: none;
            }
        }
    }
}