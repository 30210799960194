.HomeComponent{

    .titleContainer{
        background: rgb(0, 12, 29);
        padding: 5vh 0;
        border-top: 1px #333 solid;
        border-bottom: 1px #333 solid;

        &.override{
            background: transparent;
            border: none;
            padding: 0px;
            position: relative;
            margin-bottom: -46px;
            top: 46px;
            z-index: 999;
        }

        h3{
            color: #f4d506;
            font-weight: bold;
        }
    }

}