.ValidityContainers{
    // padding: 10vh 0;

    > .container-fluid{
         > .row {
            position: relative;
            background-size: cover;
            background-position: center;

            .box{
                padding: 14vh 0;
                color: #fff;

                h2{
                    font-weight: bold;
                    font-size: 2.3rem;
                }
                p{
                    padding: 20px 10vw;
                    font-weight: bold;
                    font-size: 1.5rem;
                }
                a{
                    font-size: 1.1rem;
                    padding: 8px 15px;
                    border: 1px #fff solid;
                    text-decoration: none;
                    transition: 0.2s ease-in-out all;

                    &:hover{
                        color: #000 !important;
                        background: #fff;
                    }
                }
            }
         }
    }
}