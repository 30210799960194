.MainHeader{
    .bg-dark{
        background: black !important;
        text-transform: uppercase;

        .nav-item{
            padding: 0 15px;
            
            a{
                color: #fff;
                transition: all 0.2s ease-in-out;

                &:hover{
                    color: #f4d506;
                }
            }
        }
    }
    .dropdown-menu{
        left: 15px;
        background: rgba(0,0,0,0.7);
        border-radius: 0;
        top: calc(100% + 13px);
        margin: 0;
        color: #fff;
        padding: 0;
        min-width: 200px;

        .dropdown-item{
            color: #fff;
            outline: none;
            font-size: 14px;
            padding: 0;
            transition: 0.2s all ease-in-out;

            a{
                padding-left: 20px;
            }

            &:hover{
                background: transparent;
                color: #f4d506;
            }
        }

        .dropdown-divider{
            margin: 0;
            border-color: #333;
        }
    }
}