.App{
    margin-top: 94px;
}
.MainHeader{
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
}
.pageHeader{
    padding: 14vh 0;
    background-position: center;
    background-size: cover;
    color: #fff;
}

.engineContainer{
    background: #fff;
    padding: 12vh 0;

    h3{
        color: #000;
        font-weight: bold;
    }

    .col-md-8{
        padding-top: 15px;
        img{
            margin-right: 10px;
        }
    }
}

.FaqComponent{
    .contentBox{
        .imageWrap{
            display: inline-block;
            margin-bottom: 15px;
            position: relative;

            img {
                width: 100%;
            }
        
             > p {
                &.imageText {
                    position: absolute;
                    margin: 0;
                    width: 100%;
                    padding: 1vw;
                    text-transform: uppercase;
                    color: #fff;
                    background: rgba(0,0,0,0.7);
                    font-size: 1.5rem;
                    font-weight: bold;
                }
            }
    
        }
    
        button{
            background: transparent !important;
            color: #000 !important;
    
            .expandIcon{
                float: right;
                margin: 1px;
                background: #eaeaea;
                width: 30px;
                height: 30px;
                padding: 8px;
                border-radius: 50%;
                transition: all 0.2s ease-in-out;
    
                &:hover{
                    background: #ccc;
                }
            }
    
        }
    }

    table{
        width: 95% !important;

        tr{
            td, *{
                font-family: inherit !important;
            }
            p{
                margin: 10px 0;
            }
        }
    }

    .conferencesList{
        .row{
            // border-top: 1px #eaeaea solid;
            padding: 10px 0;
            font-size: 14px;

            &.listHeading{
                font-size: 16px;
                color: #003584;
            }

            &:nth-child(even){
                background: #effaff;
            }
        }
    }

    .galleryBox{
        .col-md-3{
            border: 1px #fff solid;
            background: #eaeaea;
        }
        img{
            max-height: 150px;
        }
    }

    .ourClients{
        padding: 0;
        
        .row {
            padding-bottom: 30px;
        }
        h2{
            &.categoryTitle{
                font-size: 18px;
                border-bottom: 1px #ccc solid;
                padding: 10px;
            }
        }
        .clientBox {
            font-size: 14px;
            // margin: 15px 0;

            p{
                margin: 0;

                &:first-child {
                    color: #0956c7;
                    font-weight: 500;
                }
            }
            .col-xs-6{
                width: 50%;
                float: left;

                img{
                    border: 1px #eaeaea solid;
                }
            }
        }
    }

}

.partnerLogos {
    .container{
        padding: 8vh 0;
    }
    .logoBox {
        width: 240px;
        min-height: 140px;
        display: inline-block;
        vertical-align: middle;
        background: #eaeaea;
        margin: 20px auto;
        box-shadow: 0px 20px 40px -20px #333;
        position: relative;
        bottom: 0;
        transition: all 0.2s ease-in-out;

        &:hover{
            bottom: 5px;
            box-shadow: 0px 30px 40px -20px #333;
        }
    }
}

.reportSlider2{

    .slick-slider{
        max-width: 90%;
        margin: auto;

        .slick-slide{
            img{
                box-shadow: 0px 0px 19px -10px #333;
                margin: 10px auto !important;
            }
        }
    }

    .slick-prev,
    .slick-next{
        &::before{
            color: #333;

            @media screen and (min-width: '600px'){
                font-size: 30px;
            }
        }
    }

    .slick-dots{
        bottom: inherit;

        @media screen and (max-width: '600px'){
            display: none !important;
        }
    }
}

.adoptCityButtonTop{
    margin: 0 20px;
    background: #f4d506;
    transition: all 0.2s ease-in-out;

    &:hover{
        background: #dabe00;
    }
    
    a {
        text-align: center;
        text-transform: none;
        font-weight: bold;
        color: #000 !important;
    }
}

.selectRegionsModal {
    .modal-content {
        background-color: #061935;
        color: #fff;

        .modal-header {
            .close {
                text-shadow: none;
                color: #fff;
            }
        }
    }
    .col-md-4 {
        padding: 0;
    }
    .btn {
        &.btn-link {
            margin-bottom: 10px;
            padding: 10px;

            &:hover{
                background-color: #fff;
                text-decoration: none !important;
                
                img {
                    transform: scale(1);
                }
                p {
                    font-weight: bold;
                    color: #000;
                }
            }

            img{
                max-width: 140px;
                transform: scale(0.9);
                transition: all 0.2s ease-in-out;
            }

            p {
                margin: 0;
                color: #fff;
                transition: all 0.2s ease-in-out;
            }
        }
    }
}

.playPressBtn {
    float: left;
    display: block;
    margin: 5vh 0;
    width: 100%;
    
    img {
        float: left;
        max-width: 20%;
    }
    p{
        float: left;
        max-width: 80%;
        text-align: left;
        font-family: inherit;
        color: #fff;
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding-left: 20px;
        padding-top: 60px;
    }
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ffc800;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  