.MainHeader{
    .bg-dark{
        background: #061935 !important;
    }
}

.PtmiRegionalStatsComponent,
.IndividualRegionComponent {
// .GrantApplicationFormStatsComponent{
    margin-top: -94px;
    background: #020a15;
    color: #000;

    h1, h2, h3, h4, h5, h6 {
        color: #fff;
    }

    svg {
        color: #000 !important;
        text{
            fill: #fff !important;
            font-family: inherit !important;
        }
        
        g[transform="translate(0,360)"],
        g[transform="translate(0,120)"]
        {
            display: none;
        }
    }

    .radio{
        display: inline-block !important;

        label{
            background: #333;
            // border: 1px #ccc solid;
            color: #fff;
            font-size: 0.8rem;
            padding: 3px 15px 8px 10px;
            border-radius: 30px;
            margin: 0 5px;
            cursor: pointer;

            input{
                width: 20px;
                height: 20px;
                position: relative;
                top: 5px;
                margin-right: 8px;
            }
        }
    }

    .react-switch{
        position: relative;
        top: 2px;
        right: 10px;

        .react-switch-handle{
            width: 20px !important;
            height: 20px !important;
            top: 4px !important;
        }
        &.lowest{
            .react-switch-bg{
                background: #020a15 !important;
            }
            .react-switch-handle{
                transform: translateX(5px) !important;
                background: rgb(214, 51, 51) !important;
            }
            .switchLabel{
                text-align: right !important;
            }
        }
        &.highest{
            .react-switch-handle{
                transform: translateX(66px) !important;
            }
            .switchLabel{
                text-align: left !important;
            }
        }

        .switchLabel{
            display: inline-block;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 12px;
            color: #fff;
            padding: 4px 17px;
            width: 90px;
        }

        .react-switch-bg{
            width: 90px !important;
    
            > div{
                width: 90px !important;
            }
        }
        
        &.big{
            .switchLabel{
                width: 174px;
            }
            .react-switch-bg{
                width: 174px !important;
        
                > div{
                    width: 174px !important;
                }
            }
            &.lowest{
                .react-switch-handle{
                    transform: translateX(5px) !important;
                }
            }
            &.highest{
                .react-switch-handle{
                    transform: translateX(150px) !important;
                }
            }
        }
        &.medium{
            .switchLabel{
                width: 120px;
            }
            .react-switch-bg{
                width: 120px !important;
        
                > div{
                    width: 120px !important;
                }
            }
            &.lowest{
                .react-switch-handle{
                    transform: translateX(5px) !important;
                    background: #e4ff00 !important;
                }
            }
            &.highest{
                .react-switch-handle{
                    transform: translateX(96px) !important;
                }
                .react-switch-bg{
                    background: #004aaf !important;
                }
            }
        }
    }

    .chartBox{
        background: #0d1623;
        border-radius: 5px;
        // box-shadow: 0px 10px 40px -16px #333;
        margin-bottom: 3vh;
        overflow: hidden;
        
        .titleHead{
            padding: 10px;
            // border-bottom: 1px #232323 solid;
            background: #10223c;

            h5{
                font-size: 16px;
                font-weight: normal;
                text-align: left;
                padding-left: 15px;
                padding-top: 5px;
            }
        }
    }

    .schoolBox{
        background: #004cbd;
        padding: 15px 30px;
        box-shadow: 0px 0px 35px -8px #333;
        position: relative;

        &:nth-child(2){
            background: #ea5a2c;
        }
        &:nth-child(3){
            background: #33a02c;
        }
        
        h4{
            font-size: 20px;
        }
        svg{
            margin-bottom: 20px;
            path{
                fill: #fff;
            }
        }
        .topLabel{
            font-size: 10px;
            color: #000;
            background: #1a65d6;
            padding: 4px 8px;
            // border-radius: 5px;
            position: absolute;
            right: 8px;
            bottom: 8px;
        }

        .col:nth-child(2){
            padding-right: 0;
            p{
                background: rgb(5, 68, 162);
                line-height: 20px;
                padding: 10px;
                width: 100%;
                display: block;
            }
        }
    }

}