.BannerWithSlider{
    overflow: hidden;
    .slideContainer{
        background: #000;
        padding: 20vh 0;
        min-height: 400px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    
        h1{
            color: #f4d506;
        }
    }

    .slick-dots{
        bottom: 80px;

        li{

            width: 24px;
            height: 24px;
            margin: 0 8px;

            button{
                &:before{
                    color: #fff;
                    opacity: 1;
                    content: '';
                    width: 24px;
                    height: 24px;
                    background: url('./../../images/ttBoxEmpty.png') center no-repeat;
                }
            }
            &.slick-active{
                button{
                    &:before{
                        color: #f4d506;
                        opacity: 1;
                        background: url('./../../images/ttboxFilled.png') center no-repeat;
                    }
                }
            }
        }
    }

    .slick-prev{
        left: 20px;
        z-index: 99;
    }
    .slick-next{
        right: 24px;
        z-index: 99;
    }

    .slick-prev:before,
    .slick-next:before{
        font-size: 24px;
    }

}