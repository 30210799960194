.AdoptCitySpecific {
    
    min-height: 90vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // background-attachment: fixed;
    border-bottom: 10px #fff solid;
    color: #fff;

    h1 {
        font-size: 3.5rem;
        margin: 10vh 0 0 0;
        text-shadow: 5px 8px 20px #000;
    }
    p{
        font-size: 3.5rem;
        text-shadow: 5px 8px 20px #ccc;
    }

    &.yellow{
        h1 {
            color: #ffc000;
        }
    }
    &.blue{
        h1 {
            color: #ffc000;
        }
        p{
            color: #0067b1;
        }
    }

}